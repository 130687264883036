import axios from "axios";
import React, { useEffect, useState } from "react";
import News from "../../components/FilmFestival/News";
import { apiRoutes } from "../../constants/apiRoutes";
import FilmFestivalHeader from "../../components/FilmFestival/Header";
import Layout from "../../components/Layout";
import HeroSection from "../../components/FilmFestival/HeroSection";
import routes from "../../components/constants/routes";
import { navigate } from "gatsby";
// import { Helmet } from "react-helmet";
import Helmet from "../../components/Helmet";

const FilmFestivalNews = ({ location }) => {
  const [festivalNews, setFestivalNews] = useState([]);
  const params = new URLSearchParams(location.search);
  const blogId = params.get("id");

  const fetchFilmNews = async () => {
    try {
      const { data } = await axios.get(
        apiRoutes.news(false, null, "film festival")
      );
      if (data) {
        setFestivalNews(data.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchFilmNews();
  }, []);

  // useEffect(() => {
  //   if (blogId) {
  //     navigate(`${routes.FFNews}?id=${blogId}#newstab`);
  //   }
  // }, [blogId]);

  const seoData = {
    title: "News | Film Festival",
    description:
      "Experience the world of cinema with our film festival news tab. Get the latest updates on upcoming festivals, exclusive interviews with filmmakers, reviews of must-see films, and all the buzz from the red carpet. Lights, camera, news – your ticket to the film world is just a click away!",
    url: `${process.env.GATSBY_SITE_URL}${routes.FFNews}/`,
  };

  return (
    <Layout>
      <Helmet
        title={seoData.title}
        description={seoData.description}
        url={seoData?.url}
      />

      <div className="film-festival">
        <div className="container-fluid padding-0">
          <div className="row">
            <div className="col-12">
              <HeroSection />
              <div className="head-tab mt-40">
                <FilmFestivalHeader />
                <div className="tab-content" id="nav-tabContent">
                  <News data={festivalNews} blogId={blogId} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FilmFestivalNews;
